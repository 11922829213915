
.hero-wrapper .hero-inner-wrapper .hero-text-wrapper{
    width: 100%;
    display: block;
    margin-bottom: 72px;

    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    /* font-size: var(--xxl-heading);
    line-height: var(--xxl-line-height); */
    /* identical to box height */


    color: var(--black-color);

}

.hero-wrapper .hero-inner-wrapper .heading-text{
    margin: 0;
}
.hero-wrapper .hero-inner-wrapper .heading-text:nth-child(1){
    margin-bottom: 10px;
}


.container .hero-wrapper .hero-inner-wrapper .hero-text-description-wrapper .hero-text-description{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    /* font-size: 16px;
    line-height: 24px; */
   
}


.down-right-arrow{
    width: 4em;
    height: 4em;
}
.container .hero-wrapper .hero-inner-wrapper .hero-inverted-arrow-wrapper{
    margin-top: 60px;
}
 .hire-and-links{
    float: right;
    margin-top: 5rem;
    text-align: right;
}
.hire-and-links  .portolio-2023{
    display: flex;
    align-items: end;
}

 .hire-and-links .all-links{

}

.hire-and-links .all-links .link-pair{
    display: flex;
    justify-content: space-between;
   
   
}
.hire-and-links .all-links .link-pair a{
    color: #121212;
}



.hire-and-links .hire-me-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 6%;
    margin-right: 4%;
}
.hire-and-links .hire-me-button .hire-me-button-wrapper{
    width: 60%;
}
.hire-and-links .hire-me-button .hire-me-button-wrapper .click-me{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5%;
}



.about-ref-text-wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8rem;
    gap: 1rem;
}

.about-ref-wrapper .about-ref-heading-text-1{
    margin-top: 0;
    margin-bottom: 0;
}
.about-ref-wrapper .about-ref-heading-text-1 .hash-tag{
    width: 30px;
    height: 30px;
}
.about-ref-wrapper .about-ref-heading-text-2{
    margin: 0;
}

.about-ref-inner-wrapper .about-ref-text-description-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 100px;
}
.about-ref-inner-wrapper .about-ref-text-description-wrapper .about-ref-text-description{
    display: flex;
    align-items: flex-end;
}


.about-ref-wrapper .about-ref-work-redirect{
    display: flex;
    justify-content: flex-end;
}
.about-ref-wrapper .about-ref-work-redirect .about-ref-about-me{
    display: flex;
}
.about-ref-wrapper .about-ref-work-redirect .about-ref-about-me .position-see-more{
    display: flex;
    align-items: end;
}
/* HERO FULL CONTAINER */
.hero-full-container{
    background-color: var(--light-grey);
    margin-top: 10rem;
    margin-bottom: 3rem;
}

.hero-full-container .hero-full-wrapper{
    padding: 2rem 6.25rem;

}
.hero-full-container .hero-full-wrapper .hero-exp-logo{
    display: flex;
    justify-content: space-between;
    color: var(--grey-color);
}
.hero-full-container .hero-full-wrapper .hero-exp-logo .hero-exp-desc p{
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
}

.hero-full-container .hero-full-wrapper .hero-exp-logo .hero-company-logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}
.hero-full-container .hero-full-wrapper .hero-exp-logo .hero-company-logo img{
    object-fit: contain;
    mix-blend-mode: multiply;
    
}












.wrapper  .hero-about-wrapper .hero-about{
    display: flex;
    justify-content: space-between;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    margin-right: 5rem;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-right{
    width: 65%;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-left  h2{
    margin: 0;
    font-weight: 500;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc{
    margin-top: 1rem;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc span{
    display: block;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc p:first-child{
    margin: 0;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-right .hero-about-skill{
    margin-bottom: 3rem;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-right .hero-about-skill:last-child{
    margin-bottom: 0;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-right .hero-about-skill:last-child p:last-child{
    margin-bottom: 0;
}
.wrapper  .hero-about-wrapper .hero-about .hero-about-right .hero-about-skill p:first-child{
    margin: 0;
}
 .desc-1, .desc-2, .desc-3{
  opacity: 0;
}
.hire-and-links  .hero-text-description-wrapper .hero-text-description span{
    display: block;
 }

.hero-inner-wrapper .hero-title-scroll-down{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.scroll-down{
    opacity: 0;
}
.hero-inner-wrapper .hero-title-scroll-down .scroll-down .mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid white;
    top: 170px;
    margin-left: auto;
    margin-right: auto;
  }
.wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;
    
    height: 4px;
    width: 4px;
    border: 2px solid #fff;
    -webkit-border-radius: 8px;
            border-radius: 8px;
    animation: mouse-wheel 0.6s linear infinite;
}
.hero-inner-wrapper .hero-title-scroll-down .scroll-down .scroll-down-wrapper{
    margin-bottom: 2rem;
}
.hero-inner-wrapper .hero-title-scroll-down .scroll-down {
    display: flex;
    align-items: end;
}

.svg-overlay{
    position: relative;
}
.svg-overlay .overlay-container{
    position: relative;
    margin-top: -8%;
}

/* MEDIA QUERIES */

@media all and (max-width: 768px){

    /* HERO SECTION */
    .hero-wrapper .hero-inner-wrapper{
        display: block;
    }

    .hero-wrapper .hero-inner-wrapper .hero-text-wrapper{
        font-size: 1.6rem;
        line-height: 3.625rem;
    }

    .hero-wrapper .hero-inner-wrapper .hire-and-links{
        margin-top: var(--top-margin);
    }

    .hero-wrapper   .hero-inner-wrapper .hire-and-links .hire-me-button{
        justify-content: center;
    }

    .hero-wrapper .hero-inner-wrapper .hire-and-links ul {
        flex-direction: column;
        align-items: center;
    }




    /* SMALL EXPERIENCE SECTION */
    .hero-full-container{
        margin-top: 5rem;
    }
    .hero-full-container .hero-full-wrapper {
        padding: 2rem var(--left-right-margin);
    }
    .hero-full-container .hero-full-wrapper .hero-exp-logo {
        flex-direction: column;
    }
    .hero-full-container .hero-full-wrapper .hero-exp-logo .hero-company-logo {

        align-items: center;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        margin-top: 2rem;
    }


    /* MY WORK SECTION  */
    .about-ref-text-wrapper {
        flex-direction: column;
    }

    .about-ref-text-wrapper .about-ref-text-description{
        margin-top: var(--top-margin);
    }

} 

.hero-heading-1 .heading-text{
    opacity: 0;
}


/* ANIMATIONS */

@-webkit-keyframes mouse-wheel{
    0% {
     opacity: 1;
     -webkit-transform: translateY(0);
     -ms-transform: translateY(0);
     transform: translateY(0);
   }
 
   100% {
     opacity: 0;
     -webkit-transform: translateY(15px);
     -ms-transform: translateY(15px);
     transform: translateY(15px);
   }
 }
@-moz-keyframes mouse-wheel {
 
    0% { top: 1%; }

    90% { top: 60%;}
  
    100% { top: 1%;}
 }
@-o-keyframes mouse-wheel {
 
    0% { top: 1%; }

    90% { top: 60%;}
  
    100% { top: 1%;}
 }
@keyframes mouse-wheel {
    0% { top: 1%; }

    90% { top: 60%;}
  
    100% { top: 1%;}
 }
 


svg .svg-path{
    stroke-dasharray: 840;
    stroke-dashoffset: 900;
    
}
@keyframes animate {
    to {
        stroke-dashoffset: 0;
        
    }
}