.contact-main-container {
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: relative;
  }

.contact-main-container .contact-main-wrapper{
    
    display: grid;
    place-items: center;
    margin: 5rem 0;
}

.contact-main-wrapper{
    padding-bottom: 2rem;
}

.contact-main-form{
    width: 50%;
}
.contact-main-form-heading{
    font-size: 64px;
    margin-top: 0;
    margin-bottom: 60px;
}
.contact-detail-content p{
    margin-top: 0;
    margin-bottom: 0;
}


.contact-main-form-detail-wrapper{
    display: flex;
    justify-content: center;
}

.contact-main-input input{
    width: 90%;
    padding: 17px;
    border: 1px solid gray;
    border-radius: 30px;
    margin-top: -8px;
}
.contact-main-input h6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
}
.contain-main-detail-wrapper{
    margin-top: 50px;
}

.contact-detail-location{
    margin-top: 50px;
}
.contact-detail-location .text{
    margin-top: 30px;
    font-size: 14px;
    color: #999999;
    margin-bottom: 0;
    
}
.contact-detail-location .location{
    margin-top: 5px;
    
}
.contact-detail-social ul{
    list-style-type: none;
    padding: 0;
}
.contact-detail-social ul li{
    color: #090909;
    padding-top: 12px;
    padding-bottom: 20px;
    
}
.contact-detail-social .text{
    margin-top: 50px;
    color: #999999;
}


.contact-main-form-submit{
    margin-top: 50px;
    width: 125%;
}
.contact-main-form-submit-wrapper{
    display: flex;
}
.contact-main-form-submit-wrapper .horizontal-line{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-main-form-submit-wrapper .horizontal-line .horizontal-line-wrapper{
    width: 100%;

}




/* @media all and (max-width: 768px){

    .contact-main-container{
        padding-right: 0 !important;
        padding-left: 0 !important;
        padding-top: 0 !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .contact-main-wrapper{
        padding: 0 var(--left-right-margin) !important;
    }
    .contact-main-form-detail-wrapper {
        flex-direction: column;
        width: 100%;
    } 
    .contact-main-form {
        width: 100% !important;
    }
    .contact-main-form-submit-wrapper .horizontal-line{
        width: 50% !important;
    }
    .contact-main-form-heading{
        font-size: 1.9rem;
    }
} */




.contact-detail-social ul u{
    
    color: white  !important;
}




@media(max-width: 1780px) {

}


@media(max-width: 1680px) {
    
}
@media(max-width: 1536px) {
    .contact-main-form-heading{
        font-size: 3rem;
    }

}

@media(max-width: 1280px) {
    .contact-main-form-heading{
        font-size: 2.5rem;
    }
    .contact-main-form{
        width: 60%;
    }
    
}
@media(max-width: 1024px) {
    .contact-main-form-heading{
        font-size: 2.1rem;
    }
 
  
}

@media(max-width: 840px) {
    .contact-main-form-detail-wrapper{
        flex-direction: column;
    }
    .contact-main-form{
        width: 100%;
    }
    .contact-main-form-submit-wrapper .horizontal-line{
        width: 58%;
    }
    .contact-detail-social{
        display: none;
    }
  
}


@media(max-width: 768px) {
     
  
}




@media(max-width: 640px) {
    
   
  
}
@media(max-width: 550px) {
    .contact-main-form-submit-wrapper .horizontal-line{
        width: 47%;
    }
}


@media (max-width: 475px) {
    .contact-main-form-heading{
        font-size: 1.5rem;
    }
  
}


@media (max-width: 360px) {
     
} 

