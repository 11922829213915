@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro');
*{
  cursor: none !important;
  
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  overscroll-behavior: none;
  overflow-x: hidden;
  cursor: none;
  background-color: white;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


a:link { text-decoration: none !important; }
a:hover { text-decoration: none !important; cursor: pointer !important; }
a:active { text-decoration: none !important; }
a:visited { text-decoration: none !important; }
 
:root{
  --name-size: 2rem;
  --nav-link-size: 0.8rem;
  --black-color: #090909;
  --grey-color: #959595;
  --light-grey: #F4F4F4; 
  --light-grey-color: #F4F4F4; 
  --text-color: #ffffff;
  --heading-color: #C67800;

  --background-color: #191919;
  --xxl-heading: 40px;
  --xxl-line-height: 96px;

  --xxxl-heading: 64px;
  
  --xl-heading: 2em;

  /* flex-gaps */
  --top-margin: 2rem;
  --flex-gap: 2.5rem;


  /* left-right-margin-for-wrapper */
  --left-right-margin: 2.25rem;

}
.background-grey{
  background-color: var(--light-grey);
}

.even-spacing{
  padding: 0rem 6.25rem ;
}

.line-separation{
  border: none;
  background: #D6D6D6;
  height: 1px;
  
  margin: 80px 70px 0px 0px;
}

.project-line-separation{
  border: none;
  background: var(--grey-color);
  height: 1px;
  
}

.text-bold{
  font-weight: 600;
}

.heading-color{
  color: var(--heading-color) !important;
}

/* colors */
.light-grey{
  color: var(--light-grey);
}
.grey-color{
  color: var(--grey-color)
}
.black-color{
  color: var(--black-color)
}



/* FONT SIZE CLASSES */
.text-title{
  font-size: 7rem;
}
.text-5-5{
  font-size: 5rem;
}
.text-4{
  font-size: 4rem;
}
.text-xxxl{
  font-size: 3.5rem;
}
.text-3{
  font-size: 3rem;
}
.text-2-75{
  font-size: 2.75rem;
}
.text-2-5{
  font-size: 2.5rem;
}
.text-xxl{
  font-size: 2rem;
}
.text-xl{
  font-size: 2.5rem;
}

.text-portfolio{
  font-size: 2rem !important;
}
.text-1-5{
  font-size: 1.5rem;
}
.text-l{
  font-size: 1.125rem;
}

.text-m{
  font-size: 1rem;
}
.text-s{
  font-size: 0.9rem;
}


/* LINE HEIGHT CLASSES */
 .line-xxxl{
    line-height: 6rem;
 }
 .line-xxl{
    line-height: 4.75rem;
 }
 .line-xl{

 }

 .line-l{
  line-height: 2.5rem;
 }
 .line-2{
  line-height: 2rem;
 }

 .line-m {
  line-height: 1.5rem;
 }
 .line-s{
  
 }






/* SECTION SEPERATOR */
.section-seperator-top {
  margin-top: 8rem;
}
.section-seperator-bottom {
  margin-bottom: 8rem;
}
.mb-zero{
  margin-bottom: 0 !important;
}
.mt-zero{
  margin-top: 0;
}
.mb-one{
  margin-bottom: 1rem !important;
}
.mt-two{
  margin-top: 2rem !important; 
}
.mb-two{
  margin-bottom: 2rem !important;
}
.mb-three{
  margin-bottom: 3rem !important;
}
.mb-four{
  margin-bottom: 4rem !important;
}
.mb-five{
  margin-bottom: 5rem !important;
}
.mb-six{
  margin-bottom: 6rem !important;
}


.pr-one{
  padding-right: 1rem;
}


.mt-three{
  margin-top: 3rem;
}
.mt-four{
  margin-top: 4rem;
}
.mt-five{
  margin-top: 5rem;
}


.pb-four{
  padding-bottom: 4rem;
}
.pb-five{
  padding-bottom: 5rem;
}
.pb-six{
  padding-bottom: 6rem;
}
.pb-seven{
  padding-bottom: 7rem;
}

.pb-eight{
  padding-bottom: 8rem;
}




.pt-one{
  padding-top: 1rem;
}
.pt-two{
  padding-top: 2rem;
}
.pt-three{
  padding-top: 3rem;
}
.pt-four{
  padding-top: 4rem;
}

.pt-five{
  padding-top: 5rem;
}

.pt-six{
  padding-top: 6rem;
}

.pt-seven{
  padding-top: 7rem;
}

.pt-eight{
  padding-top: 8rem;
}



.pl-1{
  padding-left: 0.2rem;
}

.over-hide{
  overflow: hidden;
}

.bg-black
{
  background-color: black;
}
.border-radius-unique{
  border-radius: 0px 0px 50px 50px;
}

 /* CONTAINER CLASS */
 /* .container{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 8rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: relative;
} */
.calendly-heading{
  
  line-height: 70px;
  letter-spacing: 1.2px;
  
  color: var(--black-color);
  text-align: center;
  padding-top: 160px;
  padding-left: 20px;
  padding-bottom: 20px;
  margin: 0 !important;
}
.calendly-book, .calendly-or{
  color: var(--grey-color);
  text-align: center;

}
.calendly-or{

}