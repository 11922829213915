.project-ref-inner-wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
    margin-bottom: 60px;
}



.project-ref-container{
    /* width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 60px;
  
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: relative; */
    margin-top: 8rem;
    
}
.project-ref-container .project-ref-wrapper .project-ref-all-projects{
    padding: 2rem 0;
}
.project-ref-heading-1{
    margin-right: 300px;
}

.project-ref-heading-text-1{
    margin-top: 0;
    margin-bottom: 0;
}

.project-ref-about-me-wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    margin-top: 100px;
}
.project-ref-about-me-wrapper .project-ref-my-projects{
    display: flex;
    align-items: flex-end;
}

.project-ref-project-details-wrapper{
    display: flex;
    justify-content: space-between;
    
}

.project-ref-project-name-heading{
    margin-top: 0;
}

.project-ref-heading-text-1, .project-ref-heading-text-2{
    font-weight: 550;
}
.project-ref-heading-text-2{
    margin-top: 0;
}




.project-ref-project-wrapper{
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.project-ref-project-wrapper .project-ref-image img{
    width: 840px;
    height: 700px;
    
}
.project-ref-project-wrapper  .project-ref-project-details-wrapper .project-description-wrapper p{
    margin-top: 0;
    margin-bottom: 0;
    color: var(--grey-color);
}
.project-ref-project-wrapper  .project-ref-project-details-wrapper  .project-redirect-link{
    display: inline-block;
    margin-top: 1.8rem;
    border-bottom: 1px solid black;
}
.project-ref-project-wrapper  .project-ref-project-details-wrapper  .project-redirect-link a{
    color: var(--black-color);
    font-weight: 600;
}
.project-ref-project-wrapper  .project-ref-project-details-wrapper  .project-redirect-link a span{
    padding-left: 5px;
}

.project-ref-project-wrapper .project-ref-content-wrapper{
    display: flex;
    align-items: center;
}



.wrapper .hero-move-contain{
    display: grid;
    place-items: center;
    min-height: 100vh;
}



/* .wrapper .hero-project-wrapper .selected-work{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */
.wrapper .hero-project-wrapper .selected-work .my-projects-button{
    width: 30%;

}

.wrapper .hero-project-wrapper .all-projects .project-item{
    display: flex;
    justify-content: space-between;
}

.wrapper .hero-move{
   width: 100%;

}

.wrapper .hero-move .hero-move-wrapper{
    display: flex;
    flex-direction: row;
}

.wrapper .hero-move .hero-move-wrapper .move-left{
    display: flex;
    align-items: center;
    width: 50%;
}
.wrapper .hero-move .hero-move-wrapper .move-left span{
    display: block;
}

.wrapper .hero-move .hero-move-wrapper .move-right{
    width: 50%;
}
.wrapper .hero-move .hero-move-wrapper .move-right .move-skill{
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
}
.wrapper .hero-move .hero-move-wrapper .move-right .move-skill .skill{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.wrapper .hero-move .hero-move-wrapper .move-right .move-skill .skill svg{
    height: 1rem;
    width: 1.1rem;
    cursor: default;
}

.wrapper .hero-move .hero-move-wrapper .move-right .move-skill .skill-reveal{
    overflow: hidden;
    height: 0;
    transition: .4s cubic-bezier(.5,.7,.4,1);
}

.reveal{
    height: 90px !important;
}

.hero-project-wrapper .all-projects .project-item {
    padding: 2rem 0;
}




.image-hover-container{
    position: relative;
}


/* .image-hover-container .ctrl-campus-image{
    height: 300px;
    width: 240px;
    background-size: cover;
    position: absolute;
    z-index: 2;
    opacity: 0;
    margin-top:-150px;
    transform:scale(0.4);
    background-image: url("../../assets/images/project-image-1.jpg");
}

.image-hover-container .swiggy-image{
    height: 300px;
    width: 240px;
    background-size: cover;
    position: absolute;
    z-index: 2;
    opacity: 0;
    margin-top:-150px;
    transform:scale(0.4);
    background-image: url("../../assets/images/project-image-1.jpg");
}

.image-hover-container .niwwrd-image{
    height: 300px;
    width: 240px;
    background-size: cover;
    position: absolute;
    z-index: 2;
    opacity: 0;
    margin-top:-150px;
    transform:scale(0.4);
    background-image: url("../../assets/images/project-image-1.jpg");
}

.image-hover-container .yono-image{
    height: 300px;
    width: 240px;
    background-size: cover;
    position: absolute;
    z-index: 2;
    opacity: 0;
    margin-top:-150px;
    transform:scale(0.4);
    background-image: url("../../assets/images/project-image-1.jpg");
} */




.image-hover-container .ctrl-campus-image .image{
    background-image: url("../../assets/images/project-image-1.jpg");
}

.image-hover-container .swiggy-image .image{
    background-image: url("../../assets/images/project-image-3.jpg");
}

.image-hover-container .niwwrd-image .image{
    background-image: url("../../assets/images/project-image-4.jpg");
}

.image-hover-container .yono-image .image{
    background-image: url("../../assets/images/project-image-3.jpg");
}




.project__card {
    top: 0;
    width: 41.87em;
    height: 27.87em;
}

.project__card, .project__image {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    left: 0;
    top: -50%;
}

.project__card__image, .project__card__inner {
    height: 100%;
    position: relative;
    width: 100%;
}
.project__card__image {
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0;
}





/* common flex layout  */
.common-flex-layout{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.common-flex-layout .title-description{
    width: 40%;
}
.common-flex-layout .title-description .heading-color{
    padding-bottom: 1rem;
}
.common-flex-layout .title-description .selected-text{
    width: 34%;
    margin-top: 1rem;   
}
.common-flex-layout .content-number{
    width: 60%;
}
.common-flex-layout .content-number .content-number-wrapper{
    display: flex;
    justify-content: space-between;
}


.common-flex-layout .content-number .content-number-wrapper .content{
    width: 80%;
}
.common-flex-layout .content-number .content-number-wrapper .number{
    width: 20%;
    text-align: right;
}



.cards{
    display: flex;
    justify-content: space-around;

}

.card{
    width: 380px;
    height: auto;
    padding: 1.5rem;
    background-color: #191919;
    overflow: hidden;
    border-radius: 1.3rem;
}

.card .profession p::before{
    content: '';
    background: white;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 0.5rem;
    display: inline-block;
}

.card .image-icon{
    margin-bottom: 1rem;
}
.card .description{
    color: #C9C9C9;
}



.move-left-wrapper svg{
    width: 100% !important;
    height: 100% !important;
}

.move-left-wrapper svg .path-2
{
    stroke-dasharray: 2700;
    stroke-dashoffset: 2700;
    
}

@keyframes pathAnimation {
    to {
        stroke-dashoffset: 0;
        
    }
}














.spacer {
    width: 100%;
    height: 50vh;
    background: #ddd;
  }
  
  /* .gallery h1 {
    font-size: 3rem;
  } */
/*   
  .gallery p {
    font-size: 2rem;
    line-height: 1.4;
  } */
  
  .gallery {
    display: flex;
    /* outline:1px solid red; */
  }
  
  .left {
    width: 50%;
  }
  
  .right {
    height: 100vh;
    /* outline:1px solid purple; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .desktopContent {
    margin: auto;
    width: 80%;
  }
  
  .desktopContentSection {
    min-height: 100vh;
    /* outline:1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .desktopPhotos {
    width: 40vw;
    height: 40vw;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4); */
  }
  
  .desktopPhoto {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* .red {
    background: crimson;
  } */
  
  /* .green {
    background: MediumSeaGreen;
  }
  
  .blue {
    background: dodgerblue;
  }
  
  .pink {
    background: deepPink;
  }
   */
  /* small screen / mobile layout */
  .mobileContent {
    display: none;
    width: 80vw;
  }
  
  .mobilePhoto {
    width: 80vw;
    height: 80vw;
    margin-top: 5em;
    border-radius: 6vw;
  }
  
  /* defines styles for screens up to 599px wide */

  .desktopPhotos .desktopPhoto div p {
    font-size: 10rem;
    text-align: center;
    margin: 0;
    padding: 0;
    font-stretch: expanded;
    color: black;
  }




  .icons {
    /* position: absolute;
    top: -40%;
    left: 10%;
    display: flex;

    padding: 1rem 0;
    width: 100%;
    white-space: normal;
    font-size: 4rem;
    transform: rotate(-30deg);
    z-index: 4; */

    
    /* top: -40%; */
    /* left: 10%; */
   
    padding: 1rem 0;
   
    width: 100%;
    white-space: normal;
  
    /* transform: rotate(-30deg); */
    z-index: 4;
}
.icons div {
    animation: run 80s linear infinite;
    animation-delay: -80s;
}
.icons div:nth-child(2) {
    animation: run2 80s linear infinite;
    animation-delay: -40s;
}
/* @keyframes run {
    0% {
        transform: translateX(100%);

    }

    100% {
        transform: translateX(-100%);

    }
}

@keyframes run2 {
    0% {
        transform: translateX(0%);

    }

    100% {
        transform: translateX(-200%);

    }

} */


.tech_icon{
    font-size: 6rem;
    opacity: 0.1;
    color: black;
}
.backend-heading {
}
.desktopContent ul {
    color: var(--black-color);
}
.technology-name{
    font-size: var(--);
}

.want-to-see-more{
    margin-top: 2rem;
    margin-bottom: 5rem;
}
.want-to-see-more a{
    color: var(--grey-color);

    text-decoration: none;
}
/* .want-to-see-more svg{
    
    width: 1.5em;
    height: 1.5em;
    opacity: 0;
    mix-blend-mode: difference;
    
} */

/* 
.want-to-see-more a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1.3px;
    left: 50%;
    position: absolute;
    background: var(--text-color);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  
  .want-to-see-more a:hover:after { 
    width: 100%; 
    left: 0; 
  } */
  
  .mobile-icons {
    display: none;
  }


  @media(max-width: 1780px){}

  @media(max-width: 1720px){
     /* ICON */
    .tech_icon{
        font-size: 5.5rem !important;
    }
  }


  @media(max-width: 1680px){
     /* ICON */
    .tech_icon{
        font-size: 5rem !important;
    }
  }

  @media(max-width: 1536px) {
    .text-s {
        font-size: 0.8rem !important; 
     }

    .text-1 {
        font-size: 0.95rem !important;
    }

    .text-1-5{
        font-size: 1.1rem !important;
    }

    .text-xxl{
        font-size: 1.6rem !important;
    }

    .text-3{
        font-size: 2.5rem !important;
    }

     /* ICON */
    .tech_icon{
        font-size: 4.7rem !important;
    }

  }

  @media(max-width: 1360px) {
     /* ICON */
    .tech_icon{
        font-size: 4.2rem !important;
    }
  }


  @media(max-width: 1280px){

    .text-s {
        font-size: 0.7rem !important; 
     }
     
    .text-1 {
        font-size: 0.8rem !important;
    }

    .text-1-5{
        font-size: 0.9rem !important;   
    }

    .text-xxl{
        font-size: 1.4rem !important;
    }
    .text-2-5{
        font-size: 2rem !important;
    }
    .text-3{
        font-size: 2rem !important;
    }

    .reveal{
        height: 50px !important;
    }
    
    /* ICON */
    .tech_icon{
        font-size: 3.5rem !important;
    }
  }

  @media(max-width: 1024px){

    .text-s {
        font-size: 0.7rem !important; 
     }
     
    .text-1 {
        font-size: 0.8rem !important;
    }

    .text-1-5{
        font-size: 0.9rem !important;
    }

    .text-xxl{
        font-size: 1.4rem !important;
    }
    .text-xxxl{
        font-size: 3rem !important;
    }

    .text-3{
        font-size: 1.6rem !important;
    }

    .desktopPhotos{
        height: 50vw !important;
    }

     /* ICON */
    .tech_icon{
        font-size: 3rem !important;
    }
    .calendly-inline-widget{
        height: 900px !important;
    }
  }

  @media(max-width: 1000px){
    .calendly-inline-widget{
        height: 1250px !important;
    }
  }

@media(max-width: 840px){
    .common-flex-layout{
        flex-direction: column !important;
    }
    .common-flex-layout .title-description, .common-flex-layout .content-number {
        width: 100% !important;
    }
    .common-flex-layout .title-description .selected-text {
        display: none !important;
    }
    .text-1-5{
        font-size: 0.8rem !important;
    }

    .hero-project-wrapper .over-hide .mb-three {
        margin-bottom: 1rem !important;
    }

    .text-2-5{
        font-size: 1.6rem !important;
    }

    .text-3{
        font-size: 1.4rem !important;
    }
    .text-xxxl{
        font-size: 2.5rem !important;
    }
    
}


@media  (max-width: 768px){

    /* PROJECTS SECTION */
    .project-ref-container{
        margin-top: 3.125rem;
    }
    .project-ref-project-wrapper{
        flex-direction: column;
        padding-top: 2rem !important;
        padding-bottom: 1.5rem !important;
    }
    .even-spacing {
        padding: 0rem var(--left-right-margin);
    }
    .project-ref-project-wrapper .project-ref-image img {
        width: 100%;
        height: 100%;
        margin-top: 1.5rem;
    }

    .text-3{
        font-size: 1.3rem !important;
    }
    .text-xxxl{
        font-size: 2.3rem !important;
    }

    .desktopPhotos{
        height: 60vw !important;
    }

     /* ICON */
    .tech_icon{
        font-size: 2.6rem !important;
    }

    



}

@media(max-width: 640px){
    .text-1-5{
        font-size: 0.7rem !important;
    }
    .text-xxxl{
        font-size: 2rem !important;
    }

     /* ICON */
     .tech_icon{
        font-size: 2.2rem !important;
    }

    .calendly-inline-widget{
        height: 1100px !important;
    }
    
}

@media  (max-width: 550px) {
    /* .left {
      display: none;
    }
  
    .right {
      height: auto;
      width: 100%;
      align-items: center;
    } */
  
    /* .desktopPhotos {
      display: none;
    } */
  
    /* .mobileContent {
      display: block;
    } */

    .common-flex-layout .content-number .content-number-wrapper .content{
        width: 100% !important;
    }
    .common-flex-layout .content-number .content-number-wrapper .number{
        display: none;
    }
    .text-xxl{
        font-size: 1.2rem !important;
    }

    .all-projects .project-item .project p {
        font-size: 0.8rem !important;
    }

    .text-2-5{
        font-size: 1.2rem !important;
    }
    .text-xxxl{
        font-size: 1.5rem !important;
    }

    .gallery{
        flex-direction: column !important;
    }
    .left{
        width: 100% !important;
    }
    .right{
        display: none !important;
    }
    .mobile-icons{
        display: block !important;
    }
    .tech_icon{
        opacity: 1 !important;
    }
    .desktopContentSection{
        min-height: 60vh !important;
    }

  }


  @media (max-width: 475px){

    .text-2-5{
        font-size: 1.1rem !important;
    }
    .calendly-inline-widget{
        height: 1100px !important;
    }
  }

  @media (max-width: 360px){
    .all-projects .project-item .project p {
        font-size: 0.65rem !important;
    }

  }