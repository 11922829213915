#behance-button {
    color: white;
    background-color: #121212;
    padding: 1rem 2rem;
    border: none;
    border-radius: 1.8rem;
}




@media(max-width: 1280px) {
    
    #behance-button {
        font-size: 0.9rem !important;
        padding: 0.8rem 1.8rem;
    }
    
}
@media(max-width: 1024px) {
    
    #behance-button {
        font-size: 0.8rem !important;
        padding: 0.7rem 1.7rem;
    }
  
}



@media(max-width: 768px) {
    #behance-button {
        font-size: 0.6rem !important;
        padding: 0.5rem 1.3rem;
    }
  
}

