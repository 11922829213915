
@media(max-width: 1780px) {

}


@media(max-width: 1680px) {
    .about-content-wrapper .about-content .left .text-title, .project-content-wrapper .project-content .left .text-title{
        font-size: 8.75rem !important;
    }
    .about-content-wrapper .about-content .right .text-1-5, .project-content-wrapper .project-content .right .text-1-5{
        font-size: 1.4rem !important;
    } 
}
@media(max-width: 1536px) {
    .about-content-wrapper .about-content .left .text-title, .project-content-wrapper .project-content .left .text-title{
        font-size: 7.5rem !important;
    }
    .about-content-wrapper .about-content .right .text-1-5{
        font-size: 1.3rem !important;
    } 

}

@media(max-width: 1280px) {
    .about-content-wrapper .about-content .left .text-title, .project-content-wrapper .project-content .left .text-title{
        font-size: 5.7rem !important;
    }
    .about-content-wrapper .about-content .right .text-1-5, .project-content-wrapper .project-content .right .text-1-5{
        font-size: 1.1rem !important;
    } 
    .wrapper .journey-wrapper .journey .left{
        width: 30% !important;
        justify-content: left !important;
    }
    .wrapper .journey-wrapper .journey .right{
        width: 70% !important;
    }

    .wrapper .journey-wrapper .journey .right .text-1-5{
        font-size: 1.1rem !important;
    }
    .wrapper .journey-wrapper .journey .right .text-l, .wrapper .journey-wrapper .journey .left ul li{
        font-size: 0.9rem !important;
    }
    .hobbies .text-xxl, .about-interest-content h1{
        font-size: 1.5rem !important;
    }
    .wrapper .project-content-wrapper .project-content .right span{
        display: inline !important;
    }

}
@media(max-width: 1024px) {
    .about-content-wrapper .about-content .left .text-title, .project-content-wrapper .project-content .left .text-title{
        font-size: 4.2rem !important;
    }
    .about-content-wrapper .about-content .right .text-1-5, .project-content-wrapper .project-content .right .text-1-5{
        font-size: 0.9rem !important;
    } 
    .wrapper .about-content-wrapper .about-content .right span{
        display: inline !important;
    }
 
    .about-interest-wrapper .about-interest-v1{
        flex-direction: column !important;
    }
    
    .about-interest-v1 .hobbies-wrapper{
        justify-content: end !important;
        width: 100% !important;
    }
    .about-interest-wrapper .about-interest-v1 .img{
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .about-interest-content-wrapper{
        width: 60% !important;
    }
    .hobbies .text-xxl, .about-interest-content h1{
        font-size: 1.1rem !important;
    }


    .project-content-wrapper{
        margin-top: 3rem !important;
    }
    .project-content-wrapper .mb-five{
        margin-bottom: 4rem !important;
    } 
  
}



@media(max-width: 768px) {
    .about-content-wrapper .about-content .left .text-title, .project-content-wrapper .project-content .left .text-title{
        font-size: 3rem !important;
    }
    .about-content-wrapper .about-content .right .text-1-5, .project-content-wrapper .project-content .right .text-1-5{
        font-size: 0.9rem !important;
    } 

    .wrapper .journey-wrapper .journey .right .text-1-5{
        font-size: 1rem !important;
    }
    .wrapper .journey-wrapper .journey .right .text-l, .wrapper .journey-wrapper .journey .left ul li{
        font-size: 0.75rem !important;
    }
    
    .about-interest-content-wrapper{
        width: 80% !important;
    }
    .about-interest-content p, .hobbies p{
        font-size: 0.9rem !important;
    }
}




@media(max-width: 640px) {
    .wrapper .journey-wrapper .journey .left{
        display: none !important;
    }
    .wrapper .journey-wrapper .journey .right{
        width: 100% !important;
    }
    .wrapper .journey-wrapper .journey .right .text-1-5{
        font-size: 1.1rem !important;
    }
    .wrapper .journey-wrapper .journey .right .text-l, .wrapper .journey-wrapper .journey .left ul li{
        font-size: 0.8rem !important;
    }
   
}

@media(max-width: 550px) {
    .wrapper .about-content-wrapper .about-content, .wrapper .project-content-wrapper .project-content{
        flex-direction: column;
    }
    .wrapper .about-content-wrapper .about-content .left, .wrapper .about-content-wrapper .about-content .right, 
    .wrapper .project-content-wrapper .project-content .left, .wrapper .project-content-wrapper .project-content .right
    {
        width: 100% !important;
    }
    .about-interest-content-wrapper{
        width: 90% !important;
    }
    .about-interest-wrapper .about-interest-v1 .img img{
        max-width: 100% !important;
    }
    .about-interest-content p, .hobbies p{
        font-size: 0.8rem !important; 
    }
    .project-content-wrapper .mb-five{
        margin-bottom: 3rem !important;
    } 
}


@media (max-width: 475px) {
    .about-content-wrapper .about-content .left .text-title, .project-content-wrapper .project-content .left .text-title{
        font-size: 2rem !important;
    }
    .wrapper .journey-wrapper .journey .right .text-1-5{
        font-size: 0.9rem !important;
    }
    .wrapper .journey-wrapper .journey .right .text-l, .wrapper .journey-wrapper .journey .left ul li{
        font-size: 0.7rem !important;
    }

    .about-interest-content-wrapper{
        width: 100% !important;
    }
    .wrapper .journey-wrapper .journey .right .text-1-5{
        font-size: 0.93rem !important;
    }
    .about-interest-content p, .hobbies p{
        font-size: 0.7rem !important;
    }
  
}


@media (max-width: 360px) {
     
} 