.header_button_wrapper a {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    background: white;
    transition: all 250ms ease;
    outline: none;
    overflow: hidden;
    text-decoration: none;
    position: relative;
    z-index: 2;
    cursor: none;
}

.header_button_wrapper a .header_button_inner_content {
    width: 87px !important;
    height: 57px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.header_button_wrapper a #header_button_circle {
    background-color: black;
    border-radius: 50%;
    color: #000;
    height: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: height 0.35s, width 0.35s;
    width: 0;
    z-index: 0;

}

.header_button_wrapper a .header_button_inner_content span {
    color: black;
    font-size: 13px;
    font-family: var(--semiBold);
    margin-top: 12px;
    transition: all 250ms ease;
}



.header_button_wrapper a:hover {
    box-shadow: inset 0 -160px 0 -10px black;
    transform: scale(1.2);
}
.header_button_wrapper a:hover .button-value{
    color: white;
}
.header_button_wrapper a:hover .button-svg path{
    fill: white;
}
