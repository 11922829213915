/* Desktop container utility class: */


/* .container {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
} */



/* NAVIGATION BAR */



/* .container {
  
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  

} */










/* 

@media(max-width: 1780px) {

}


@media(max-width: 1680px) {
    
}
@media(max-width: 1536px) {


}

@media(max-width: 1280px) {
   
    
}
@media(max-width: 1024px) {
    
 
  
}



@media(max-width: 768px) {
     
  
}




@media(max-width: 640px) {
    
   
  
}
@media(max-width: 550px) {
    
}


@media (max-width: 475px) {
   
  
}


@media (max-width: 360px) {
     
} */





.wrapper{
  max-width: 1680px;
  padding: 0 5rem;
  margin: 0 auto;
  overflow: hidden !important;
}


/* MAIN PAGE */


  @media (min-width: 1780px) {
    .wrapper{
      max-width: 1680px;
    }
}


@media(max-width: 1780px) {
 
}


@media (max-width: 1680px) {
  .wrapper{
    max-width: 1536px;
    
  }  
}


  /* 2xl */
@media (max-width: 1536px) {
  .wrapper{
    max-width: 100%; 
  }
}
  


  /* xl */
@media (max-width: 1280px) {
  .wrapper {
    max-width: 100%; 
    
  }
}
  



  /* lg */
@media (max-width: 1024px) {
  .wrapper {
      max-width: 100%; 
      padding: 0 2.6rem;
  }
}
  


  /* md */
@media (max-width: 768px) {
  .wrapper {
    max-width: 100%;
    padding: 0 1.5rem; 
  }
}


  
  /* sm */
@media (max-width: 640px) {
    .wrapper {
      max-width: 100%; 
      padding: 0 20px;
    }
}

@media (max-width: 550px){
  .wrapper {
    max-width: 100%; 
    padding: 0 20px;
  }
}


  /* xs */
@media (max-width: 475px) {
  .wrapper {
    max-width: 100%; 
    padding: 0 20px;
  }
}


@media (max-width: 360px) {
  .wrapper {
    max-width: 100%; 
    padding: 0 20px;
  }
}























