.wrapper .role-duration-location{
    display: flex;
    justify-content: space-between
}


.content-wrapper{
    display: flex;
    justify-content: space-between;
}

.content-wrapper .left{
    width: 50%;
}
.content-wrapper .right{
    width: 50%;
}
.campus-div{
    margin-top: 8rem;
}
.campus-div img, .wrapper .campus-introduction img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}




.hero-project2-container{
    background-image: url('../../../assets/project_images/Niwwrd/background_image.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: right;
    background-size: contain;
    background-color: #F4F4F4;
    
}

.hero-project2-container .project-content-wrapper .content{
    margin: 0 auto;
    padding: 0 5rem;
    position: relative;
}
.hero-project2-container .project-content-wrapper .content p {
    width: 30%;
}
.hero-project2-container .project-content-wrapper .content .behance_redirect{
    float: right;
}
.final_ui_img{
    margin: 1rem 0;
}

.wrapper .campus-div img, .wrapper .campus-introduction img{
    max-width: 100%;
}