.navigation{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.nav-name{
    color: white;
    font-size: var(--name-size);
}

.navigation-links ul{
    list-style-type: none;
    display: flex;
    gap: 3rem;
    padding-left: 0;
}
.navigation-links ul >li {
    font-size: var(--nav-link-size);
}
.navigation-links .over-hide a{
  color: var(--grey-color) !important;
}

.navigation-sidebar ul{
    list-style-type: none;
    font-size: var(--nav-link-size);
}
.nav-Link-react-router{
    color: var(--grey-color);
}


.navigation-links span, .navigation-logo{
  opacity: 0;
}
.navigation-links a{
 
  text-decoration: none;
  position: relative;
}
.navigation-links a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 1.3px;
  left: 50%;
  position: absolute;
  background: var(--text-color);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.navigation-links a:hover:after { 
  width: 100%; 
  left: 0; 
}















@media(max-width: 1780px) {

}


@media(max-width: 1680px) {
    
}
@media(max-width: 1536px) {
  .navigation .navigation-logo h3{
    font-size: 1.5rem !important;
  }
  
  .navigation .text-m {
      font-size: 0.9rem !important;
  }

}

@media(max-width: 1280px) {
  .navigation .navigation-logo h3{
    font-size: 1.5rem !important;
  }

  .navigation .text-m {
      font-size: 0.85rem !important;
  }

    
}
@media(max-width: 1024px) {
    /* NAVBAR */
    .navigation .navigation-logo h3{
        font-size: 1.5rem !important;
    }
    .navigation .text-m {
        font-size: 0.7rem !important;
    }
    
 
  
}



@media(max-width: 768px) {
     
   /* NAVBAR */
   .navigation .navigation-logo h3{
    font-size: 1.25rem !important;
  }
  .navigation .text-m {
    font-size: 0.7rem !important;
  }

}




@media(max-width: 640px) {
    
    /* NAVBAR */
    .navigation .navigation-logo .nav-name{
        font-size: 1.5rem  !important;
      }
      .navigation .text-m {
        font-size: 0.7rem !important;
      }
      .navigation .navigation-links ul{
        gap: 2rem !important;
      }
  
   
  
}
@media(max-width: 550px) {
    
}


@media (max-width: 475px) {
   
  
    /* NAVBAR */
    .navigation{
        justify-content: space-between !important;
        margin-top: 1.8rem !important;
        align-items: flex-start;
      }
      .navigation .navigation-logo {
        display: block !important;
      }
      .navigation .navigation-links ul{
        gap: 0.6rem !important;
        flex-direction: column;
        margin-top: 0;
      }
      .navigation .text-m {
        font-size: 0.7rem !important;
      }
      .navigation .navigation-logo .nav-name{
        margin-top: 0;
      }
  
}


@media (max-width: 360px) {
      /* NAVBAR */
      .navigation .navigation-links ul{
        flex-direction: column !important;
      }
} 