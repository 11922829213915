.wrapper .role-duration-location{
    display: flex;
    justify-content: space-between
}


.content-wrapper{
    display: flex;
    justify-content: space-between;
}

.content-wrapper .left{
    width: 50%;
}
.content-wrapper .right{
    width: 50%;
}
.campus-div{
    margin-top: 8rem;
}
.campus-div img, .wrapper .campus-introduction img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}




.hero-project-container{
    background-image: url('../../../assets/project_images/ctrlcampus/background_img.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: right;
    background-size: contain;
    background-color: black;
    
}

.hero-project-container .project-content-wrapper .content{
    margin: 0 auto;
    padding: 0 5rem;
    position: relative;
}
.hero-project-container .project-content-wrapper .content p {
    width: 30%;
}
.hero-project-container .project-content-wrapper .content .behance_redirect{
    float: right;
}

.wrapper .campus-div img, .wrapper .campus-introduction img{
    max-width: 100%;
}











@media(max-width: 1280px) {
   
    
    .hero-project-container .text-4, .hero-project2-container .text-4{
        font-size: 3rem !important;
    }
    .wrapper .campus-introduction .text-3,.campus-div .content-wrapper .text-3{
        font-size: 2rem !important;
    }
}
@media(max-width: 1024px) {
    .hero-project-container, .hero-project2-container{
        background-size: cover !important;
    }
    .wrapper .role-duration-location{
        flex-direction: column;
    }
    .wrapper .campus-introduction .text-3,.campus-div .content-wrapper .text-3{
        font-size: 2rem !important;
    }
    .hero-project-container .project-content-wrapper .content p, .hero-project2-container .project-content-wrapper .content p{
        width: 60% !important;
    }
  
}



@media(max-width: 768px) {
     
  
}




@media(max-width: 640px) {
    
   .campus-div .content-wrapper {
    flex-direction: column;
   }
   .campus-div .content-wrapper .left, .campus-div .content-wrapper .right{
    width: 100%;
   }
   .hero-project-container .behance_redirect, .hero-project2-container .behance_redirect {
    margin-top: 1rem !important;
   }
   .hero-project-container .project-content-wrapper .content, .hero-project2-container .project-content-wrapper .content{
    padding: 0 1rem !important;

   }
}
@media(max-width: 550px) {
    .hero-project-container .text-4, .hero-project2-container .text-4{
        font-size: 2rem !important;
    }
    .wrapper .campus-introduction .text-3,.campus-div .content-wrapper .text-3{
        font-size: 1.4rem !important;
    }
    .hero-project-container .project-content-wrapper .content p, .hero-project2-container .project-content-wrapper .content {
        width: 100% !important;
    }
}


@media (max-width: 475px) {
   
  
}


@media (max-width: 360px) {
     
} 

