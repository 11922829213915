.footer-container{
    background-color: #1B1C1F;
    padding-top: 50px;
  
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    /* position: fixed;
    left: 0;
    bottom: 0;
    width: 100%; */

}
.footer-container .footer-wrapper-v1{
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    
}
.footer-container .footer-wrapper-v1 .footer-inner-wrapper{
    display: flex;
    justify-content: space-between;
    gap: 200px;
}
.footer-container .footer-wrapper-v1 .footer-inner-wrapper .footer-inner-title{
    margin-right: 150px;
}

.footer-container .footer-wrapper-v1 .footer-inner-wrapper .footer-inner-email p{
    padding: 20px;
    border: 1px solid #505050;
    border-radius: 35px;
    font-size: 20px;

}
.footer-inner-title-text{
    font-size: 38px;
    padding-top: 0;
    margin-top: 0;
}
.footer-inner-wrapper{
    display: flex;
}

.footer-wrapper-nav-links{
    display: flex;
}
.footer-wrapper-nav{
    margin-top: 30px;
}
.footer-wrapper-nav .footer-wrapper-nav-title{
    color: #AAAAAA;
    margin-bottom: 20px;

}
.footer-wrapper-nav-links .footer-wrapper-nav-link-item{
    margin-right: 20px;
    color: white;
}
.footer-design-develop{
    /* margin-top: 100px;
    margin-right: 80px;
    margin-left: 80px; */
}
.footer-design-develop-wrapper{
   
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 60px;
}
.footer-design-develop-wrapper .footer-designed-by-item{
    color: #AAAAAA;
}
.footer-designed-by-item-val{
    color: white;
}

.footer-inner-title-icon-img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;

}

.footer-social-media u {
  text-decoration: none !important;
}









.wrapper .footer-email-talk{
    display: flex;
    justify-content: space-between;
}
.wrapper .footer-email-talk .footer-inner-title-icon{
    width: 80%;
}

.wrapper .footer-email-talk .footer-email{
    width: 20%;
}
.wrapper .footer-design-develop .footer-design-develop-wrapper{
    display: flex;
    justify-content: space-between;
    color: var(--grey-color) !important;
}

.wrapper .footer-design-develop .footer-design-develop-wrapper ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    color: #121212;
}
.wrapper .footer-design-develop .footer-design-develop-wrapper ul li a{
    padding-right: 1rem;
    color: #121212 !important;
}
.wrapper .footer-design-develop .footer-design-develop-wrapper .footer-social-media span:first-child{
    padding-right: 1rem;
}



.wrapper .footer-email-talk  .footer-inner-email-wrapper{
    display: flex;
    align-items: end;
}
.wrapper .footer-email-talk  .footer-inner-email-wrapper .footer-inner-email p{
    padding: 20px;
    border: 1px solid #505050;
    border-radius: 35px;
    font-size: 1.3rem;

}

.wrapper .footer-email-talk .footer-inner-title-icon span{
    display: block;

}


























@media(max-width: 1780px) {

}


@media(max-width: 1680px) {
    
}
@media(max-width: 1536px) {
      /* FOOTER */
      .footer-email-talk .footer-inner-title-icon {
        line-height: 3.5rem;
      }
      .footer-email-talk .footer-inner-title-icon .text-4{
        font-size: 3rem;
      }
      .footer-email-talk .footer-inner-title-icon .footer-inner-title-icon-img{
        width: 60px;
        height: 60px;
      }

      .wrapper .footer-email-talk .footer-inner-email-wrapper .footer-inner-email p{
        font-size: 1.2rem;
      }

}

@media(max-width: 1280px) {
    /* FOOTER */
    .footer-email-talk .footer-inner-title-icon {
        line-height: 3.2rem;
        }
        .footer-email-talk .footer-inner-title-icon .text-4{
        font-size: 2.5rem;
        }
        .footer-email-talk .footer-inner-title-icon .footer-inner-title-icon-img{
        width: 50px;
        height: 50px;
        }
    
        .wrapper .footer-email-talk .footer-inner-email-wrapper .footer-inner-email p{
        font-size: 1rem;
        }
        .footer-horizontal-line .mb-five{
        margin-bottom: 3rem !important;
        }
        .footer-horizontal-line .mt-five{
        margin-top: 3rem !important;
        }
        .footer-design-develop .footer-social-media .social-media{
        display: none;
        }
        .wrapper .footer-design-develop .footer-design-develop-wrapper ul{
        padding-left: 0;
        }
    
}
@media(max-width: 1024px) {
    
 
    /* FOOTER */
    .footer-email-talk .footer-inner-title-icon {
        line-height: 2.5rem;
      }
      .footer-email-talk .footer-inner-title-icon .text-4{
        font-size: 1.9rem;
      }
      .footer-email-talk .footer-inner-title-icon .footer-inner-title-icon-img{
        width: 45px;
        height: 45px;
      }
  
      .wrapper .footer-email-talk .footer-inner-email-wrapper .footer-inner-email p{
        font-size: 0.9rem;
      }
      .footer-horizontal-line .mb-five{
        margin-bottom: 2rem !important;
      }
      .footer-horizontal-line .mt-five{
        margin-top: 2rem;
      }
      /* .footer-design-develop .footer-designed-by .designed-by, .footer-design-develop  .footer-developed-by .developed-by{
        display: none;
      } */
      .footer-design-develop-wrapper{
        padding-bottom: 2rem;
      }
  
}



@media(max-width: 768px) {
     
    /* FOOTER */
    .footer-email-talk .footer-inner-title-icon {
        line-height: 2rem;
      }
      .footer-email-talk .footer-inner-title-icon .text-4{
        font-size: 1.5rem;
      }
      .footer-email-talk .footer-inner-title-icon .footer-inner-title-icon-img{
        width: 40px;
        height: 40px;
      }
  
      .wrapper .footer-email-talk .footer-inner-email-wrapper .footer-inner-email p{
        font-size: 0.7rem;
      }
      .footer-horizontal-line .mb-five{
        margin-bottom: 2rem !important;
      }
      .footer-design-develop-wrapper {
        font-size: 0.9rem !important;
      }
}




@media(max-width: 640px) {
    
    /* FOOTER */
    .footer-email-talk{
        flex-direction: column;
      }
      .footer-email-talk .footer-inner-title-icon {
        width: 100% !important;
        line-height: 2.5rem;
      }
      .footer-email-talk .footer-inner-title-icon .footer-inner-title-icon-img{
        width: 50px;
        height: 50px;
      }
      .footer-email-talk .footer-inner-title-icon .text-4{
        font-size: 2.15rem;
      }
      .footer-email-talk .footer-inner-email-wrapper{
        margin-top: 1.5rem;
      }
      .wrapper .footer-email-talk .footer-inner-email-wrapper .footer-inner-email p{
        font-size: 1rem;
      }
  
      .footer-design-develop .footer-designed-by , .footer-design-develop  .footer-developed-by .prateek{
        display: none;
      }
      .wrapper .footer-design-develop .footer-design-develop-wrapper{
        justify-content: space-around !important;
      }
      .wrapper .footer-design-develop .footer-design-develop-wrapper ul{
        gap: 2rem;
      }
  
}
@media(max-width: 550px) {
    
}


@media (max-width: 475px) {
    /* FOOTER */
    .footer-email-talk{
        flex-direction: column;
      }
      .footer-email-talk .footer-inner-title-icon {
        width: 100% !important;
        line-height: 2.1rem;
      }
      .footer-email-talk .footer-inner-title-icon .footer-inner-title-icon-img{
        width: 50px;
        height: 50px;
      }
      .footer-email-talk .footer-inner-title-icon .text-4{
        font-size: 1.5rem;
      }
      .footer-email-talk .footer-inner-email-wrapper{
        margin-top: 1.2rem;
      }
      .wrapper .footer-email-talk .footer-inner-email-wrapper .footer-inner-email p{
        font-size: 0.9rem;
      }
  
      .wrapper .footer-design-develop .footer-design-develop-wrapper ul{
        gap: 0.6rem;
      }
  
  
  
}


@media (max-width: 360px) {
     /* FOOTER */
     .footer-email-talk .footer-inner-title-icon .text-4{
        font-size: 1.2rem;
      }
      .footer-design-develop-wrapper {
        font-size: 0.8rem !important;
      }
}