

.container .contact-ref-wrapper{
    padding: 0 var(--left-right-margin);
}
.contact-ref-inner-wrapper{
    width: 800px;
    margin-bottom: 90px;
}

.contact-ref-text-wrapper .contact-ref-heading-2{
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
}
.contact-ref-text-wrapper .contact-ref-heading-1{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
}

.contact-ref-text-wrapper .heading-text{
    font-size: 48px;
    padding-top: 0;
    margin-top: 0;
}
.contact-ref-text-wrapper .heading-text:nth-child(1){

    margin-bottom: 5px;
}

.contact-ref-contact-me-wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    margin-left: 30px;
    margin-right: 30px;
}
.contact-ref-contact-me-smile{
    display: flex;
    align-items: flex-end;
}






/* MEDIA QUERIES FOR MOBILE */
@media all and (max-width: 768px){

    /* CONTACT PART */
    .contact-ref-inner-wrapper{
        width: auto !important;
      
    }
    .contact-ref-text-wrapper .heading-text{
        font-size: 1.875rem !important;
    }
    .contact-ref-text-wrapper .contact-ref-heading-2 {
        justify-content: flex-end !important; 
    }
    .contact-ref-contact-me-wrapper {
        flex-direction: column;
        margin-top: 3.75rem !important;
    }
    .contact-ref-contact-me-smile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .contact-ref-contact-me{
        display: grid;
        place-items: center;
    }

}