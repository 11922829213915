body{
  background-color: white !important;
}

@media(max-width: 1780px) {
 /* MAIN PAGE  */
      /* SECTION 2 */
      .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
        font-size: 3.1rem !important;
      }
      .wrapper .hero-about-wrapper .hero-about .hero-about-left{
        width: 35% !important;
        margin-right: 3rem !important;
      }
      .wrapper .hero-about-wrapper .hero-about .hero-about-right{
        width: 65% !important;
      }
     
        /* SECTION 3 */
        /* SECTION 4 */
}


@media(max-width: 1680px) {
    /* MAIN PAGE */
        /* SECTION 1 */
        .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
            font-size: 6.5rem !important;
          }
        .hire-and-links .portolio-2023 .text-portfolio{
          font-size: 1.75rem !important;
        }
        .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl, .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-xxl, .hero-move-contain .hero-move .move-right .move-skill .skill .text-xxl{
          font-size: 1.65rem !important;
        }
        .hire-and-links-wrapper .hero-text-description-wrapper .line-l{
          line-height: 2.35rem !important;
        }

        /* SECTION 2 */

        .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
          font-size: 2.8rem !important;
        }
        .wrapper .hero-about-wrapper .hero-about .hero-about-left{
          width: 35% !important; 
          margin-right: 1rem !important;
        }
        .wrapper .hero-about-wrapper .hero-about .hero-about-right{
          width: 65% !important;
        }
       

          /* SECTION 3 */
          /* SECTION 4 */
}
@media(max-width: 1536px) {

      /* MAIN PAGE */
        /* SECTION 1 */
          .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
              font-size: 5rem !important;
            }
          .hero-wrapper .hero-text-description-wrapper .text-1,  .hero-inner-wrapper .scroll-down .scroll-down-wrapper p {
              font-size: 0.95rem !important;
          }
          .hire-and-links .portolio-2023 .text-portfolio{
            font-size: 1.85rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl, .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-xxl, .hero-move-contain .hero-move .move-right .move-skill .skill .text-xxl {
            font-size: 1.5rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .line-l{
            line-height: 2.35rem !important;
          }
  
  
          /* SECTION 2 */
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
            font-size: 2.6rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left{
            width: 40% !important;
            margin-right: 1rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-right{
            width: 60% !important;
          }
          .wrapper .hero-about-wrapper .pt-five{
            padding-top: 0rem !important;
          }

          /* SECTION 3 */
          .hero-project-wrapper .all-projects .project-item .project .text-5-5{
            font-size: 5rem !important;
          }
          .hero-move-contain .hero-move .move-left .text-5-5{
            font-size: 4rem !important;
          }

          .hero-project-wrapper .all-projects .project-item .slash .text-xxxl{
            font-size: 2.5rem !important;
          }
          
          .hero-project-wrapper .all-projects .project-item .slash .text-xxxl{
            font-size: 3rem;
          }
          /* SECTION 4 */

}

@media(max-width: 1280px) {
   
    
      /* MAIN PAGE */
        /* SECTION 1 */
          .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
              font-size: 4.2rem !important;
            }
          .hero-wrapper .hero-text-description-wrapper .text-1, .hero-inner-wrapper .scroll-down .scroll-down-wrapper p {
              font-size: 0.85rem !important;
          }
          .hero-inner-wrapper .scroll-down .scroll-down-wrapper {
            margin-bottom: 1rem !important;
          }
          .hero-inner-wrapper .hero-title-scroll-down .scroll-down .mouse {
            height: 35px !important;
            width: 16px !important;
          }
          .hire-and-links .portolio-2023 .text-portfolio{
            font-size: 1.5rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl, .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-xxl, .hero-move-contain .hero-move .move-right .move-skill .skill .text-xxl  {
            font-size: 1.15rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .line-l{
            line-height: 2rem !important;
          }

          /* SECTION 2 */
  
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
            font-size: 2rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left{
            width: 50% !important;
            margin-right: 1rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-right{
            width: 50% !important;
          }
  
          
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-l, 
          .hero-wrapper .hero-inner-wrapper .hire-and-links .all-links .link-pair a, 
          .hero-move-contain .hero-move .move-left span, 
          .hero-move-contain .hero-move .move-right .move-skill .skill .skill-reveal p
          {
            font-size: 0.8rem !important;
          }
          /* SECTION 3 */

          .hero-project-wrapper .selected-work .text-xxl{
            font-size: 1.75rem !important;
          }
          .hero-project-wrapper .all-projects .project-item .project{}
          .hero-project-wrapper .all-projects .project-item .slash{}

          .hero-project-wrapper .all-projects .project-item .project .text-5-5{
            font-size: 4rem !important;
          }
          .hero-move-contain .hero-move .move-left .text-5-5{
            font-size: 3.2rem !important;
          }
          .hero-project-wrapper .all-projects .project-item .slash .text-xxxl{
            font-size: 2.2rem !important;
          }
          /* SECTION 4 */
  
}
@media(max-width: 1024px) {
    
 

      /* MAIN PAGE */
        /* SECTION 1 */
          .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
              font-size: 3rem !important;
          }
          .hero-wrapper .hero-text-description-wrapper .text-1, .hero-inner-wrapper .scroll-down .scroll-down-wrapper p {
            font-size: 0.75rem !important;
          }
          .hero-inner-wrapper .scroll-down .scroll-down-wrapper {
            margin-bottom: 0rem !important;
          }
          .hero-inner-wrapper .hero-title-scroll-down .scroll-down .mouse {
            
            height: 27px !important;
            width: 11px !important;
            
          }
          .hero-inner-wrapper .hero-title-scroll-down .scroll-down .mouse .wheel{
            height: 2px !important;
            width: 2px !important;
          }
          .hire-and-links .portolio-2023 .text-portfolio{
            font-size: 1.25rem !important;
          }

          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl, 
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-xxl, 
          .hero-move-contain .hero-move .move-right .move-skill .skill .text-xxl  {
            font-size: 1rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .line-l{
            line-height: 1.85rem !important;
          }
  
          .wrapper .hero-wrapper .section-seperator-bottom{
            margin-bottom: 2rem !important;
          }
          .wrapper .hero-wrapper .section-seperator-top {
            margin-top: 5rem !important;
          }
  
  
          /* SECTION 2 */
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
            font-size: 1.75rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc{
            padding-right: 4rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc span{
            display: inline !important; 
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left{
            width: 45% !important;
            margin-right: 1rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-right{
            width: 55% !important;
          }
        
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-l,
          .hero-wrapper .hero-inner-wrapper .hire-and-links .all-links .link-pair a{
            font-size: 0.8rem !important;
          }

          /* SECTION 3 */
          .hero-project-wrapper .pt-five{
            padding-top: 0 !important;
          }
          .hero-project-wrapper .selected-work .text-xxl{
            font-size: 1.5rem !important;
          }
          .hero-project-wrapper .all-projects .project-item .project .text-5-5, .hero-move-contain .hero-move .move-left .text-5-5{
            font-size: 3.5rem !important;
          }
          .hero-project-wrapper .all-projects .project-item .slash .text-xxxl{
            font-size: 2rem !important;
          }

          /* SECTION 4 */
          .hero-move-contain .hero-move .hero-move-wrapper{
            flex-direction: column !important;
          }
          .wrapper .hero-move .hero-move-wrapper .move-left{
            width: 100% !important;
          }
          .wrapper .hero-move .hero-move-wrapper .move-right{
            margin-top: 4rem !important;
            width: 100% !important;
          }
          .hero-move-contain .hero-move .move-left span, 
          .hero-move-contain .hero-move .move-right .move-skill .skill .skill-reveal p{
            font-size: 1rem !important;
          }
          .hero-move-contain .hero-move .move-right .move-skill .skill .text-xxl{
            font-size: 1.5rem !important;
          }
  
}

@media(max-width: 840px){
        .hero-wrapper .hero-text-description-wrapper .text-1, .hero-inner-wrapper .scroll-down .scroll-down-wrapper p {
          font-size: 0.7rem !important;
        }
}

@media(max-width: 768px) {
     
  

      /* MAIN PAGE */
        /* SECTION 1 */
        .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
            font-size: 3.5rem !important;
          }
          .hero-wrapper .hero-text-description-wrapper .text-1, .hero-inner-wrapper .scroll-down .scroll-down-wrapper p {
            font-size: 0.75rem !important;
          }
          .hero-inner-wrapper .scroll-down .scroll-down-wrapper {
            margin-bottom: 1rem !important;
          }
          .hire-and-links .portolio-2023 .text-portfolio{
            font-size: 1rem !important;
          }
          .svg-overlay svg{
            opacity: 0;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl, 
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-xxl
          {
            font-size: 0.8rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .line-l{
            line-height: 1.5rem !important;
          }

          /* SECTION 2 */
  
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
            font-size: 1.3rem !important;
          }
          
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc{
            font-size: 0.65rem !important;
          
          }
          .hero-wrapper .hero-inner-wrapper .hire-and-links .all-links .link-pair a, .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-l{
            font-size: 0.6rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc span{
            display: inline !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc{
            font-size: 0.8rem;
            padding-right: 0 !important;
          }
          
          
          /* SECTION 3 */

          .hero-project-wrapper .all-projects .project-item .project .text-5-5, .hero-move-contain .hero-move .move-left .text-5-5{
            font-size: 3rem !important;
          }
          .hero-project-wrapper .all-projects .project-item .slash .text-xxxl{
            font-size: 1.5rem !important;
          }
          /* SECTION 4 */



}




@media(max-width: 640px) {
    
   

      /* MAIN PAGE */
        /* SECTION 1 */
        .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
            font-size: 4.15rem !important;
          }
          .hire-and-links .portolio-2023 .text-portfolio{
            font-size: 1.3rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl, 
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-xxl
          {
            margin-top: 1rem;
            font-size: 1.1rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .all-links{
            font-size: 0.9rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .line-l{
            line-height: 1.85rem !important;
          }
          .hero-wrapper .hero-inner-wrapper .hire-and-links{
            flex-direction: column !important;
          }

          /* SECTION 2 */
          .wrapper .hero-about-wrapper .hero-about{
            flex-direction: column !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left, .wrapper .hero-about-wrapper .hero-about .hero-about-right{
            width: 100% !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left {
            flex-direction: column !important;
            margin-bottom: 3rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text{
            margin-bottom: 1rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
            font-size: 1.5rem !important;
            display: inline !important;
            padding-right: 0.6rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-l,  .hero-wrapper .hero-inner-wrapper .hire-and-links .all-links .link-pair a{
            font-size: 0.8rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc{
            font-size: 0.95rem !important;
          
          }
  
          /* SECTION 3 */
          .hero-project-wrapper .selected-work .text-xxl{
            font-size: 1.3rem !important;
          }
          .hero-project-wrapper .all-projects .project-item .project .text-5-5, .hero-move-contain .hero-move .move-left .text-5-5{
            font-size: 2.5rem !important;
          }
          .hero-project-wrapper .all-projects .project-item .slash .text-xxxl{
            font-size: 1.4rem !important;
          }
          /* SECTION 4 */
  
  
  
  
}
@media(max-width: 550px) {
    
     /* MAIN PAGE */
        /* SECTION 1 */
        .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
            font-size: 3.5rem !important;
          }
          .hire-and-links .portolio-2023 .text-portfolio{
            font-size: 1.3rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl{
            margin-top: 1rem !important;    
          }

          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl, 
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-xxl
          {
            font-size: 0.91rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .all-links{
            font-size: 0.8rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .line-l{
            line-height: 1.85rem !important;
          }
          .hero-wrapper .hero-inner-wrapper .hire-and-links{
            flex-direction: column !important;
          }
          /* SECTION 2 */
         
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
            font-size: 1.2rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-l,  .hero-wrapper .hero-inner-wrapper .hire-and-links .all-links .link-pair a{
            font-size: 0.7rem !important;
          }
          /* SECTION 3 */
          .hero-project-wrapper .all-projects .project-item .project .text-5-5, .hero-move-contain .hero-move .move-left .text-5-5{
            font-size: 2.1rem !important;
          }
          .hero-project-wrapper .all-projects .project-item .slash .current, .wrapper .hero-project-wrapper .selected-work .button-wrapper{
            display: none;
          }
          /* SECTION 4 */
}


@media (max-width: 475px) {
   
  
      /* MAIN PAGE */
        /* SECTION 1 */
        .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
            font-size: 4.5rem !important;
          }

          .hero-inner-wrapper .hero-title-scroll-down {
            flex-direction: column !important;
            align-items: center !important;
            gap: 2rem !important;
          }
          .hero-heading-1 .heading-text{
            text-align: center !important ;
          }
          .hire-and-links .portolio-2023 .text-portfolio{
            font-size: 1.3rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl, 
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-xxl 
          {
            font-size: 1.1rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .text-xxl{
            margin-top: 2rem !important;
          }
          
          .hire-and-links-wrapper .hero-text-description-wrapper .all-links{
            font-size: 0.9rem !important;
          }
          .hire-and-links-wrapper .hero-text-description-wrapper .line-l{
            line-height: 1.85rem !important;
          }
          .hero-wrapper .hero-inner-wrapper .hire-and-links{
            flex-direction: column !important;
          }
          .wrapper .hero-wrapper .hero-inner-wrapper .hero-text-description-wrapper .hero-text-description span{
            display: inline !important;
          }
          /* SECTION 2 */
         
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc span{
            display: inline !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-right  .text-l,  .hero-wrapper .hero-inner-wrapper .hire-and-links .all-links .link-pair a{
            font-size: 0.8rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text .text-xxxl{
            font-size: 1.3rem !important;
          }
          .wrapper .hero-about-wrapper .hero-about .hero-about-left .offer-text-desc{
            font-size: 0.9rem !important;
          
          }
          /* SECTION 3 */
          /* SECTION 4 */
          .hero-move-contain .hero-move .move-right .move-skill .skill .text-xxl{
            font-size: 1.1rem !important;
          }
          .hero-move-contain .hero-move .move-left span{
            display: inline !important;
          }
}


@media (max-width: 360px) {
     /* MAIN PAGE */
        /* SECTION 1 */
    .hero-inner-wrapper .hero-title-wrapper .hero-heading-1 .text-title{
      font-size: 3.8rem !important;
    }
} 