
.about-hero-container-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: relative;
    margin-bottom: 100px;
}

.about-hero-title-wrapper h1{
    font-size: var(--xxxl-heading);
}
/* .about-hero-title-1-text{
    font-size: 64px;
}
.about-hero-title-2-text{
    font-size: 64px;
} */

.about-hero-desc-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.about-hero-desc-THIS{
    font-size: 24px;
}
.about-hero-desc-arrow{
    margin-top: 100px;
}
.about-hero-main-desc{
    margin-top: 50px;
}
.about-hero-desc-content-wrapper{
    display: flex;
    align-items: center;
}

.about-hero-specialize-wrapper{
    padding-left: 150px;
    padding-right: 150px;
    margin-bottom: 100px;
}
.about-hero-specialize-areas-wrapper{
    margin-top: 100px;
}
.about-hero-specialize-areas{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.about-hero-specialize-area{
   padding-right: 20px;
   padding-left: 10px;
}
.about-hero-specialize-area:nth-child(1){
    border-right: 1px solid #D6D6D6;
    padding-left: 0px  !important;
}
.about-hero-specialize-area:nth-child(2){
    border-right: 1px solid #D6D6D6;
}
.about-hero-specialize-wrapper .about-hero-specialize-title h1{
    font-size: var(--xl-heading);
}



.wrapper .about-content-wrapper .about-content{
    display: flex;
    justify-content: space-between;
  }
.wrapper .about-content-wrapper .about-content .left{
    padding-right: 3rem;
    width: 55%;
}
.wrapper .about-content-wrapper .about-content .right{
    width: 45%;
    display: flex;
    align-items: center;
}
.wrapper .about-content-wrapper .about-content .right span{
    display: block;
}




.wrapper .journey-wrapper .journey{
    display: flex;
    justify-content: space-between;
}
.wrapper .journey-wrapper .journey .left{
    width: 45%;
    display: flex;

    justify-content: center;

}
.wrapper .journey-wrapper .journey .left ul {
    list-style-type: none;
    color: #121212;
    
}
.wrapper .journey-wrapper .journey .left ul li {
    padding-bottom: 2rem;
}

.wrapper .journey-wrapper .journey .right{
    width: 55%;
}









/* MEDIA QUERIES */

@media all and (max-width: 768px){
    .about-hero-container-wrapper{
        display: block;
        padding: 0 var(--left-right-margin);
        width: auto !important;
    }
    :root{
        --xxxl-heading: 2rem;
        --xl-heading: 1.6em;
    }
    .about-hero-desc-wrapper {
        flex-direction: column;
    }
    .about-hero-desc-img- img {
        width: 100% !important;
    }
    .about-hero-desc-arrow{
        margin-top: 2rem;
    }



    .about-hero-specialize-wrapper{
        padding-left: var(--left-right-margin) !important;
        padding-right: var(--left-right-margin) !important;
    } 
    .about-hero-specialize-areas{
        flex-direction: column;
    }
    .about-hero-specialize-area{
        margin-top: 1rem;
        padding-left: 0 !important;
        border-right: 1px solid #D6D6D6;
    }
    .about-hero-specialize-areas-wrapper {
        margin-top: 2rem;
    }


}
