

.work-project-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 60px;
  
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: relative;
  }
  @media (min-width: 768px) {
    .work-project-container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .work-project-container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .work-project-container {
      width: 1170px;
    }
}

.work-project-exp-text{
    font-size: 64px;
}

.work-project-experience-wrapper{
    display: flex;
    justify-content: left;
    
}

.work-project-detail{
    display: flex;
    gap: 200px;
    margin-bottom: 50px;
}
.work-project-details-wrapper{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.work-project-detail-img-1{
    border: 80px solid #1E2A9A;
}

.work-project-detail-img-2{
    border: 80px solid #000000;
    
}

.work-project-detail-explanation{
    display: flex;
    align-items: self-start;
}
.work-project-detail-exp-wrapper p{
    margin-top: 2px;
    margin-bottom: 2px;
}
.work-project-detail-These{
    font-size: 24px;
    font-weight: 600;
    padding-right: 4px;
}

.projects-wrapper{
  margin-bottom: 200px;
}
.image_wrapper{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 32px;
}
.image_wrapper .big_image_layer{
  width: 821px;
  position: relative;
  display: flex;
  overflow: hidden;
  cursor: default;
  margin-right: 34.25%;
}
.image_wrapper .big_image_layer .big_image{
  width: 100%;
}
.image_wrapper .big_image_layer .big_image .big_image_1{
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  cursor: default;
}

.image_wrapper .big_image_layer .big_image .about_img_content{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 32px;
  transition: all 250ms ease-in-out;
  background: rgba(0, 0, 0, 0.4);
  color: white;
}

.image_wrapper .big_image_layer .big_image:hover .about_img_content{
  opacity: 100%;
  
}





.image_wrapper_2{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  
}

.image_wrapper_2 .small_image_layer{
  position: relative;
  width: 32%;
} 
.image_wrapper_2 .small_image_layer_1{
  top: 100px;
}
.image_wrapper_2 .small_image_layer_3{
  top: -150px;
}
.image_wrapper_2 .small_image_layer .small_image{
  width: 100%;
  line-height: 0;
}
.image_wrapper_2 .small_image_layer .small_image img{
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  cursor: default;
  
}
.image_wrapper_2 .small_image_layer .small_image .small_about_img_content{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;

  transition: all 250ms ease-in-out;
  background: rgba(0, 0, 0, 0.4);
  color: white;
}


.image_wrapper_2 .small_image_layer .small_image:hover .small_about_img_content{
    opacity: 100%;
}
.image_wrapper_2 .small_image_layer .small_image .small_about_img_content .content{
  padding-left: 30px;
}











.wrapper .project-content-wrapper .project-content{
  display: flex;
  justify-content: space-between;
}
.wrapper .project-content-wrapper .project-content .left{
  padding-right: 3rem;
  width: 55%;
}
.wrapper .project-content-wrapper .project-content .right{
  width: 45%;
  display: flex;
  align-items: center;
}
.wrapper .project-content-wrapper .project-content .right span{
  display: block;
}