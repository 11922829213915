
.about-interest-v1{
    display: flex;
    justify-content: center;
    gap: var(--flex-gap);
}
.about-interest-v1  .hobbies-wrapper{
    display: flex;
    align-items: end;
    text-align: right;
}

.about-interest-content-wrapper{
    width: 30%;
    display: flex;
    align-items: start;
}




/* @media all and (max-width: 768px){

   
    .about-interest-container{
        display: block;
    }
    .about-interest-wrapper{
        padding: 0 var(--left-right-margin);
    }
    .about-interest-v1 .about-interest img{
        width: 100% !important;
    }
    .about-interest-v1{
        flex-direction: column;
        gap: 0 !important;
    }
    .about-interest-content-wrapper {
        width: 100% !important;
    }
} */