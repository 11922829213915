#redirect-button {
    color: #121212;
    padding: 1rem 2rem;
    border: 1px solid #121212;
    border-radius: 1.8rem;
}
.button-wrapper{
    margin-bottom: 1rem;
    margin-top: 1rem;
}




@media(max-width: 1280px) {
    
    #redirect-button {
        font-size: 0.9rem !important;
        padding: 0.8rem 1.8rem;
    }
    
}
@media(max-width: 1024px) {
    
    #redirect-button {
        font-size: 0.8rem !important;
        padding: 0.7rem 1.7rem;
    }
  
}



@media(max-width: 768px) {
    #redirect-button {
        font-size: 0.6rem !important;
        padding: 0.5rem 1.3rem;
    }
  
}


