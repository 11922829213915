.preload-text-wrapper {
    position: fixed;
    top: 80%;
    left: 84%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    cursor: none !important;
    font-size: 3rem;
    padding: 5px;
    width: 400px;
    overflow: hidden;
    
}
.preload-text{
    display: flex;
    gap: 20px;
}   

.preload-text .transitioning-text{
    color: var(--heading-color);
}
body{
    background-color: black !important;
}

@media  (max-width: 1360px) {
    .preload-text-wrapper {
        top: 84% !important;
        font-size: 2.5rem !important;
    }
}


@media  (max-width: 920px) {
    .preload-text-wrapper {
        top: 87% !important;
        font-size: 2.3rem !important;
    }
}
@media  (max-width: 768px) {
    .preload-text-wrapper {
        top: 87% !important;
        left: 87% !important;
        font-size:   2rem !important;
    }
}


/* Extra small devices (phones) */
@media (max-width: 600px) {
   
  }
  
 
  @media  (max-width: 768px) {
    
  }
  

  @media (max-width: 495px) {
    .preload-text-wrapper {
        top: 87% !important;
        left: 87% !important;
        font-size:   1.8rem !important;
    }
  }
  
  
